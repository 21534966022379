import * as React from 'react';
import Link from '@mui/joy/Link';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "../../assets/css/swagger-joy.css";
import { getAuthHeader } from '../../lib/client';

export default function SwaggerPage({ error, setError }) {


  const addAuthHeader = async (req) => {

    let header = await getAuthHeader();
    if (header && req.headers) {
      Object.assign(req.headers, header);
    } else {
      setError(<><Link href="/login" sx={{ color: '#fff' }}>Login</Link> to try out the API</>);
    }
    return (req);
  };


  return <SwaggerUI
    url={`${process.env.REACT_APP_BACKEND_SERVER}/api/api-docs`}
    tryItOutEnabled={false}
    deepLinking={true}
    displayOperationId={true}
    displayRequestDuration={true}
    requestInterceptor={addAuthHeader}
  />;
};
