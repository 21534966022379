
import { UltravoxSession } from 'ultravox-client';
import { Room, RoomEvent } from 'livekit-client';
import { api } from '../lib/client';

const backend = new URL(process.env.REACT_APP_BACKEND_SERVER);

let session, room, listenerId, socket, ws;

const transformFunctions = (functions) => functions?.map?.(({ name, description, parameters, url, implementation, method, key }) => {
  let pUrl = url?.length && new URL(url);
  return {
    name,
    description,
    url,
    implementation,
    method,
    key,
    input_schema: {
      type: "object",
      properties:
        parameters?.reduce((o, p) => {
          let isIn = pUrl?.pathname?.includes(`{${p.name}}`) && 'path';
          isIn = isIn || (pUrl?.searchParams?.has(p.name) && 'query');
          isIn = isIn || (method === 'post' ? 'body' : 'query');
          return {
           ...o, [p.name]: {
              type: p.type,
              in: isIn,
              description: p.description
            }
          };
        }, {}) || {},
    }
  };
});

export async function createAgent({ modelName, prompt, options, functions, keys, onClose, onMessage, audioModel, requestTelephony }) {
  console.log({ modelName, prompt, options, functions, keys, onClose, onMessage, audioModel, requestTelephony }, 'createAgent');
  let { data } = await api.post('/agents', { modelName, prompt, options, functions: transformFunctions(functions), keys });
  let { id } = data;
  let livekit, ultravox;
  console.log({ id, data }, 'agent create id');
  ({ data } = await api.post(`/agents/${id}/listen`, { number: requestTelephony ? '*' : undefined, options: { streamLog: true } }));
  ({ socket, id: listenerId } = data || {});
  if (!requestTelephony && listenerId) {
    ({ data: { livekit, ultravox } } = await api.post(`/rooms/${listenerId}/join`, {}));
  }
  console.log({ id, data, listenerId, livekit, ultravox, requestTelephony }, 'agent create id');

  if (socket) {
    let wsPath = `${backend.protocol === 'https:' ? 'wss:' : 'ws:'}//${backend.host}${socket}`;
    console.log({ id, data, wsPath }, 'opening socket');
    ws = new WebSocket(wsPath);
    console.log({ wsPath, ws }, 'wsPath');
    ws.addEventListener('message', async (message) => {
      try {
        console.log({ message }, 'socket message');
        data = JSON.parse(message.data);
        onMessage && onMessage(data);
      }
      catch (e) {
      }
    });
    ws.addEventListener('error', (err) => {
      console.error(err, 'ws error');
    });
    ws.addEventListener('close', (err) => {
      onClose && onClose(err.message);
    });
    data.ws = ws;
  }
  if (ultravox) {
    const debugMessages = new Set(["debug"]);
    // sparse array of function calls
    let calls = [];
    let transcriptLen = 0;
    session = new UltravoxSession({ experimentalMessages: debugMessages });
    // stub any client functions as we don't implement them in the playground
    functions.filter((func) => func.implementation === 'client')
      .forEach((func) => {
        console.log({ name: func.name, result: func.result }, 'registering client function')
        session.registerToolImplementation(func.name, () => func.result);
      });
    ['status', 'transcripts', 'experimental_message']
      .forEach(event => session.addEventListener(
        event,
        (msg) => {
          let { transcripts } = session || {};
          let call;
          console.log(JSON.stringify({ transcripts, msg, calls, transcriptLen }, null, 2), 'transcripts');
          let debug = msg?.message && msg?.message?.type && msg?.message?.message;
          if (debug && debug.startsWith('LLM response: \nTool calls:')) {
            let [[, method, body]] = debug.matchAll(/FunctionCall\(name='([^']*)'.*args='([^']*)'.*\)/ig).toArray() || [[]];
            call = { rest_callout: { method, body, url: "" } };
          }
          else if (debug && debug.startsWith('Tool call complete. Result: ')) {
            let [[, body, name]] = debug.matchAll(/.*role: MESSAGE_ROLE_TOOL_RESULT[\s\S]*text: "(.*)"[\s\S]*tool_name: "(.*)"/ig).toArray() || [[]];
            call = { function_results: [{ name, input: [], result: body.replace(/\\/g, '') }] };
          }
          call && (calls[transcriptLen] = (calls[transcriptLen] || [])) && calls[transcriptLen].push(call);
          transcripts && onMessage && onMessage(transcripts.map((t, ind) => ([{ [t.speaker]: t.text }, ...(calls[ind] || [])])).flat());
          transcriptLen = (transcripts && transcripts?.length - 1) || transcriptLen;
        }
      ));
    session.joinCall(ultravox.joinUrl);

    data.ws = true;
  }
  else if (livekit) {
    let { serverUrl, participantToken } = livekit;
    let audioElement = new Audio();
    room = new Room({
      disconnectOnPageLeave: true
    });

    room
      .on(RoomEvent.TrackSubscribed, (track) => track.attach(audioElement))
      .on(RoomEvent.TrackUnsubscribed, (track) => track.detach())
      .on(RoomEvent.DataReceived, (payload, participant) => console.log({ payload, participant }, 'data received'))
      .on(RoomEvent.Disconnected, (reason) => {
        console.log({ reason }, 'disconnected');
      })
      .on(RoomEvent.DataReceived, (payload, participant, kind) => {
        //const data = JSON.parse(decoder.decode(payload));
        //console.log({ data, participant, kind }, 'data received');
        //onMessage && onMessage(data); 
      });
    await room.connect(serverUrl, participantToken);
    room.localParticipant.setMicrophoneEnabled(true);
    audioElement.play();
    data.ws = true;
  }


  return { ...data, id, listenerId };
}

export async function listModels() {
  let { data } = await api.get('/models');
  console.log({ data }, 'models');
  return Object.entries(data);
}

export async function updateAgent({ id, prompt, options, functions, keys }) {
  let { data } = await api.put(`/agents/${id}`, { prompt, options, functions: transformFunctions(functions) });
  return data;
}

export async function stopAgent({ id }) {
  try {
    console.log({ id, session, listenerId, socket }, 'stopAgent');
    session && session.leaveCall && session.leaveCall();
    room && room.disconnect && room.disconnect();
    room = session = null;
    ws && ws.close && ws.close();
    
    listenerId && await api.delete(`/agents/${id}/listen/${listenerId}`);
  }
  catch (e) {
    // we may be trying to delete an agent because of a failed network, don't care too much
  }
}

export async function deleteAgent({ id }) {
  try {
    console.log({ id, session }, 'deleteAgent');
    session && session.leaveCall && session.leaveCall();
    room && room.disconnect && room.disconnect();
    room = session = null;
    ws && ws.close && ws.close();
    let { data } = id && await api.delete(`/agents/${id}`);
    return data;
  }
  catch (e) {
    // we may be trying to delete an agent because of a failed network, don't care too much
  }
}

export async function listVoices() {
  let { data } = await api.get('/voices');
  return data;
}